/*
 *
 * OrdersPage reducer
 *
 */

import ActionTypes from './constants';
import moment from 'moment';
import { ContainerState, ContainerActions } from './types';

const loadingResources = Array(48)
  .fill(0)
  .map((v, i) => ({ loading: true, id: i }));

export const initialState: ContainerState = {
  orders: {
    data: loadingResources,
  },
  search: {
    filter: {
      supplierName: '',
      statuses: [],
      orderDateRange: {
        start: moment().startOf('month'),
        end: moment().endOf('month'),
      },
      deliveryDateRange: null,
    },
    pagination: {
      page: 0,
      size: 48,
    },
  },
};

function ordersPageReducer(state: ContainerState = initialState, action: ContainerActions) {
  const { type, payload } = action as any;
  switch (type) {
    case ActionTypes.FETCH_ORDERS_REQUEST:
      return {
        ...state,
        orders: { ...state.orders, loading: true },
      };
    case ActionTypes.FETCH_ORDERS_SUCCESS:
      const { orders } = payload;
      return {
        ...state,
        orders: { ...orders, loading: false },
      };
    case ActionTypes.ORDERS_EXPORT_CSV_SUCCESS:
      return {
        ...state,
        search: payload.search,
      };
    case ActionTypes.SET_FILTER:
      return {
        ...state,
        search: payload,
      };
    default:
      return state;
  }
}

export default ordersPageReducer;
