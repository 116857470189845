/*
 *
 * OrdersPage actions
 *
 */

import { action, createAsyncAction } from 'typesafe-actions';

import ActionTypes from './constants';

export const orders = createAsyncAction(
  ActionTypes.FETCH_ORDERS_REQUEST,
  ActionTypes.FETCH_ORDERS_SUCCESS,
  ActionTypes.FETCH_ORDERS_FAILURE,
)<{}, {}, Error>();

export const ordersExportCSV = createAsyncAction(
  ActionTypes.ORDERS_EXPORT_CSV_REQUEST,
  ActionTypes.ORDERS_EXPORT_CSV_SUCCESS,
  ActionTypes.ORDERS_EXPORT_CSV_FAILURE,
)<{}, {}, Error>();

export const setFilter = (data: any) => action(ActionTypes.SET_FILTER, data);
export const applyFilter = (data: any) => action(ActionTypes.APPLY_FILTER, data);
export const clearFilter = () => action(ActionTypes.CLEAR_FILTER);
export const exportCSV = (data: any) => action(ActionTypes.EXPORT_CSV, data);
