/*
 *
 * OrdersPage constants
 *
 */

enum ActionTypes {
  DEFAULT_ACTION = 'app/OrdersPage/DEFAULT_ACTION',
  FETCH_ORDERS_REQUEST = 'app/OrdersPage/FETCH_ORDERS_REQUEST',
  FETCH_ORDERS_SUCCESS = 'app/OrdersPage/FETCH_ORDERS_SUCCESS',
  FETCH_ORDERS_FAILURE = 'app/OrdersPage/FETCH_ORDERS_FAILURE',
  FETCH_SUPPLIERS_FAILURE = 'app/OrdersPage/FETCH_SUPPLIERS_FAILURE',
  ORDERS_EXPORT_CSV_REQUEST = 'app/OrdersPage/ORDERS_EXPORT_CSV_REQUEST',
  ORDERS_EXPORT_CSV_SUCCESS = 'app/OrdersPage/ORDERS_EXPORT_CSV_SUCCESS',
  ORDERS_EXPORT_CSV_FAILURE = 'app/OrdersPage/ORDERS_EXPORT_CSV_FAILURE',
  EXPORT_CSV = 'app/OrdersPage/EXPORT_CSV',
  SET_FILTER = 'app/OrdersPage/SET_FILTER',
  APPLY_FILTER = 'app/OrdersPage/APPLY_FILTER',
  CLEAR_FILTER = 'app/OrdersPage/CLEAR_FILTER',
  SET_SEARCH = 'app/OrdersPage/SET_SEARCH',
}

export default ActionTypes;
