import { createSelector } from 'reselect';
import { ApplicationRootState } from 'types';
import { initialState } from './reducer';

/**
 * Direct selector to the ordersPage state domain
 */

const selectOrdersPageDomain = (state: ApplicationRootState) => {
  return state ? state.ordersPage : initialState;
};

/**
 * Other specific selectors
 */

/**
 * Default selector used by OrdersPage
 */

const selectOrdersPage = () =>
  createSelector(selectOrdersPageDomain, substate => {
    return substate;
  });

const selectSearchPage = () =>
  createSelector(selectOrdersPageDomain, substate => {
    return substate.search;
  });

export default selectOrdersPage;
export { selectOrdersPageDomain, selectSearchPage };
